<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
              徽章管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active>徽章列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <div class="col-12 d-flex col-xl-6 mb-1 justify-content-between">
            <h4 class="font-weight-bold">
              徽章列表
            </h4>
            <b-button class="flex-shrink-0 flex-shrink-0 mb-2 mt-xl-1" variant="primary" :to="{ name: 'BadgeCreate' }"><i
                class="fa fa-plus" aria-hidden="true"></i>新增徽章
            </b-button>
          </div>
          <div class="
              col-12 col-xl-6
              d-flex
              flex-column flex-xl-row
              align-items-end align-items-xl-center
            ">
            <b-input-group class="ml-0 ml-xl-4">
              <b-form-input placeholder="名稱" v-model="keyword" v-on:keyup.enter="fetchBadges(currentPage)"></b-form-input>
              <b-input-group-append>
                <b-button @click="fetchBadges(currentPage)"><i class="fa fa-search"></i></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-table responsive striped hover :items="badges" :fields="fields" @sort-changed="handleSort">
          <template #cell(image_url)="data">
            <b-img-lazy v-if="data.item.image_url" class="thumb-image" :src="data.item.image_url" alt="徽章圖片" width="50"
              height="50" />
          </template>
          <template #cell(started_at_and_end_at)="data">
            <div class="mb-1">{{ formatDate(data.item.available_start_at) }}</div>
            <div>{{ formatDate(data.item.available_end_at) }}</div>
          </template>
          <template #cell(branch)="data">
            <div class="mb-1">
              {{ data.item.branch ? data.item.branch.name : "" }}
            </div>
            <div>
              {{ data.item.branch ? data.item.branch.branch_code : "" }}
            </div>
          </template>
          <template #cell(arrange)="data">
            <b-button class="ml-2" variant="inverse-warning" v-if="checkPermission([consts.BADGE_EDIT])" :to="{
              name: 'BadgeEdit',
              params: {
                badgeId: data.item.id,
              },
            }">編輯
            </b-button>
            <b-button class="ml-2" variant="inverse-primary" v-if="checkPermission([consts.BADGE_EDIT])" :to="{
              name: 'BadgeCustomerList',
              params: {
                badgeId: data.item.id,
              },
            }">會員列表
            </b-button>
          </template>
        </b-table>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"></b-pagination>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>

import badgeApi from "@/apis/badge";
import moment from "moment";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";

export default {
  data() {
    return {
      consts,
      keyword: null,
      showLoading: false,
      currentPage: 1,
      totalRows: 1,
      perPage: 15,
      fields: [
        {
          key: "name",
          label: "徽章名稱",
        },
        {
          key: "image_url",
          label: "徽章圖片",
        },
        {
          key: "description",
          label: "徽章描述",
        },
        {
          key: "sort",
          label: "排序",
          sortable: true,
        },
        {
          key: "is_enabled",
          label: "狀態",
          formatter: (value) => {
            return value ? "啟用" : "停用"
          },
        },
        {
          key: "arrange",
          label: "管理",
        },
      ],
      badges: [],
      permissionChecker: new PermissionChecker(),
      sortBy: 'sort',
      orderBy: 'asc',
    };
  },
  watch: {
    currentPage: function () {
      this.fetchBadges()
    }
  },
  mounted() {
    this.fetchBadges();
  },
  methods: {
    async fetchBadges() {
      this.showLoading = true;

      try {
        const params = {
          page: this.currentPage,
          sort_by: this.sortBy,
          order_by: this.orderBy,
          per_page: this.perPage || 15
        };
        if (this.keyword) params.keyword = this.keyword;

        const { data } = await badgeApi.getBadges(params);

        this.badges = data.data;
        this.totalRows = data.meta.total;
        this.perPage = data.meta.per_page;
      } catch (error) {
        console.error(error);
        alert("取得徽章資料錯誤");
      }
      this.showLoading = false;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    handleSort(ctx) {
      this.sortBy = ctx.sortBy;
      this.orderBy = ctx.sortDesc ? "desc" : "asc";
      this.currentPage = 1;
      this.fetchBadges();
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
